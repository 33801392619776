import React from 'react';
import { SingleStyles } from '../../styles/SingleDvdStyles.js';
import dvd_image from '../../assets/images/sgm-soce-2005-1a.jpg';
import Footer from '../../components/Footer';
import SEO from '../../components/SEO';
import CheckoutSOCE05 from '../../components/CheckoutSOCE-05';

export default function SocePage05() {
  return (
    <>
      <SEO title="SGM | Suddenly on Christmas Eve 2005" />
      <SingleStyles>
        <div className="wrap">
          <section className="single-item">
            <h2>Suddenly on Christmas Eve (2005)</h2>
            <div className="product">
              <div className="product-image">
                <img src={dvd_image} />
              </div>
              <div className="description">
                <p>
                  This performance was recorded live at the Casey Theater, Regis
                  College, in Weston, MA.
                </p>
                <p className="price">
                  <span>DVD</span> $40.00
                </p>
                <CheckoutSOCE05 />
              </div>
            </div>
          </section>
        </div>
      </SingleStyles>
      <Footer />
    </>
  );
}
