import styled from 'styled-components';

export const SingleStyles = styled.div`
  padding: 20px 0 100px;
  background-color: var(--white);
  .single-item {
    display: block;
  }
  .product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .product-image {
      width: 45%;
      img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
        border-radius: 4px;
      }
    }
    .description {
      width: 45%;
    }
    p.price {
      width: 100%;
      margin: 0 0 40px;
      color: var(--red);
      line-height: 1.2;
      font-size: 2rem;
      font-size: clamp(12px, 5vw, 22px);
      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
      @media screen and (max-width: 650px) {
        font-size: clamp(12px, 5vw, 22px);
      }
      span {
        font-family: LeagueGothic;
        font-size: inherit;
        font-weight: 400;
        display: inline-block;
        padding: 4px 12px 2px;
        margin-right: 4px;
        line-height: inherit;
        border-radius: 4px;
        text-transform: uppercase;
        color: var(--black);
        background-color: var(--grey);
      }
    }
    @media screen and (max-width: 650px) {
      display: block;
      .product-image,
      .description {
        width: 100%;
      }
    }
  }
  div {
    transition: all 0.24s ease-in-out;
  }
  @media screen and (max-width: 650px) {
    display: block;
    h2 {
      font-size: 32px;
      line-height: 1.1;
    }
  }
`;
